import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Hero from "./Hero"
import Section from "./Section"

const Root = () => {
  return (
    <>
      <div className=" overflow-hidden w-full">
        <Hero />
      </div>
      <div className="pc:mt-40 sp:mt-20 pc:max-w-6xl pc:mx-auto">
        <Section
          title="Company"
          subTitle="私たちについて"
          description="社員やお客様など、関わる人すべてに夢を与えられるような会社づくりを目指しています。<br/>
            FunMeetsという社名は、「Fun（楽しい、ワクワク）」「Meets（場所）」を掛け合わせた造語。<br/>
            これから入社する社員一人ひとりの夢やお客様の夢への実現をサポートしたいと考えています。"
          isOdd
        >
          <StaticImage
            src="../../../images/home/company.jpg"
            className="w-full"
            quality={95}
            alt="Company"
          />
        </Section>
        <div className="pc:grid pc:grid-cols-3 pc:gap-x-6 pc:mt-40 sp:px-4 sp:mt-16">
          <section className="flex-1 text-center">
            <h3 className="pc:text-4xl sp:text-2xl font-bold text-brand-primary poppins underline">
              Mission
            </h3>
            <p className="mt-5 font-bold">「〜関わる人に夢を〜」</p>
          </section>
          <section className="flex-1 sp:mt-10 text-center">
            <h3 className="pc:text-4xl sp:text-2xl font-bold text-brand-secondary poppins underline">
              Vision
            </h3>
            <p className="mt-5 font-bold leading-8">
              私たちは、
              <br />
              お客様にとって、社員にとって、世の中にとって、理想的な構造を造りたいと考えています。
              <br />
              社員の個性や経験に合った仕事をつかむチャンス、お客様のニーズにあった環境を提供します。
            </p>
          </section>
          <section className="flex-1 sp:mt-10 text-center">
            <h3 className="pc:text-4xl sp:text-2xl font-bold text-brand-primary poppins underline">
              Value
            </h3>
            <p className="mt-5 font-bold leading-8">
              1. 夢を持つ
              <br />
              2. 主体的に行動し常に学び続ける
              <br />
              3. 人に対して誠実である
            </p>
          </section>
        </div>
        <Section
          title="Service"
          subTitle="サービスについて"
          description="教育事業、SES事業、受託事業、採用コンサルティング事業を主に展開しております。<br/>
          これからテクノロジーがますます進歩しITが生活をより豊かにする時代。<br/>
          デジタルトランスフォーメーション（DX）を促進するため、<br/>
          ITを軸に、お客様へお手伝いができるところが当社の強みです。"
          isOdd={false}
        >
          <StaticImage
            src="../../../images/home/service.jpg"
            quality={95}
            alt="Service"
          />
        </Section>
        <Section
          title="Career"
          subTitle="キャリア"
          description="エンジニア、クリエイターの募集要項になります。<br/>
          自身の成長や夢の実現に向けて働く人を応援し、<br/>
          未経験者にも積極的にスキルアップの機会を提供したいと考えています。<br/>
          レベルに合ったプロジェクト先を選定していきます。安心してご相談ください。"
          isOdd
        >
          <StaticImage
            src="../../../images/home/career.jpg"
            quality={95}
            alt="Career"
          />
        </Section>
        {/* <Section
          title="Contact"
          subTitle="お問い合わせ"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum."
          isOdd={false}
        >
          <StaticImage
            src="../../../images/home/contact.jpg"
            quality={95}
            alt="Contact"
          />
        </Section> */}
      </div>
    </>
  )
}

export default Root
