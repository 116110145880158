import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const settings = {
  accessibility: false,
  draggable: false,
  swipe: false,
  fade: true,
  autoplay: true,
  infinite: true,
  speed: 2000,
  autoplaySpeed: 6000,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: "linear",
}

const Hero = () => {
  return (
    <div className="sp:w-full">
      <Slider {...settings}>
        <div>
          <StaticImage
            src="../../../images/home/hero-01.png"
            className="w-full"
            quality={95}
            alt="Company"
          />
        </div>
        <div>
          <StaticImage
            src="../../../images/home/hero-02.png"
            className="w-full"
            quality={95}
            alt="Company"
          />
        </div>
      </Slider>
    </div>
  )
}

export default Hero
